
  export default {
    data() {
      return {
        unsubscribe: undefined,
        lock: false,
        show: false,
        message: '',
        color: '',
        timeout: -1,
        url: '',
        urlTitle: '',
        applicationUrl: '',
        width: '',
        host: '',
        reloadButton: '',
      };
    },
    watch: {
      show() {
        if (this.show === false) {
          this.hide();
        }

        if (
          !this.lock &&
          !this.show &&
          this.$store.getters['notifications/hasNotificationsPending']
        ) {
          this.getNewNotificationFromStore();
        }
      },
    },
    mounted() {
      this.host = location.origin;
    },
    created() {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'notifications/showNotification' && !this.lock) {
          this.getNewNotificationFromStore();
        }
      });
    },
    destroyed() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    },
    methods: {
      hide() {
        this.lock = false;
        this.show = false;
        this.message = '';
        this.color = '';
        this.timeout = -1;
        this.url = '';
        this.urlTitle = '';
        this.applicationUrl = '';
        this.width = '';
        this.reloadButton = '';
      },
      getNewNotificationFromStore() {
        this.lock = true;
        this.$store
          .dispatch('notifications/getOldestNotification')
          .then((notification) => {
            this.show = true;
            this.message = notification.message;
            this.color = notification.color;
            this.timeout = notification.timeout;
            this.url = notification.url;
            this.urlTitle = notification.urlTitle;
            this.applicationUrl = notification.applicationUrl;
            this.width = notification.width;
            this.reloadButton = notification.reloadButton;
          });
      },
      reload() {
        location.reload();
      },
    },
  };
